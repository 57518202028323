import React from "react"

export default function Footer() {
  return (
    <footer id="footer" className="bg-dark text-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">Copyright © All Rights Reserved</div>
        </div>
      </div>
    </footer>
  )
}
