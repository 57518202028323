import React, { Component } from "react"
import config from "../../config"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Nav from "../components/Nav"
import Intro from "../components/Intro"
import What from "../components/What"
import Games from "../components/Games"
// import Price from "../components/Price"
import Partners from "../components/Partners"
// import Faq from "../components/Faq"
import Directions from "../components/Directions"
import Contact from "../components/Contact"
import Footer from "../components/Footer"
import Scrolltotop from "../components/Scrolltotop"

class IndexPage extends Component {
  state = {
    isMenuOpen: false,
  }

  render() {
    return (
      <Layout>
        <div
          id="preloader"
          className="animated fadeOut"
          style={{
            backgroundColor: "#000",
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            zIndex: 2000,
          }}
        >
          <div className="pulse" />
        </div>
        <div className="border-top" />
        <div className="border-bottom" />
        <div className="border-left" />
        <div className="border-right" />
        <Header
          hamburgerClicked={e =>
            this.setState({ isMenuOpen: !this.state.isMenuOpen })
          }
          isMenuOpen={this.state.isMenuOpen}
        />
        <Nav
          hamburgerClicked={e =>
            this.setState({ isMenuOpen: !this.state.isMenuOpen })
          }
          isMenuOpen={this.state.isMenuOpen}
        />
        <div id="body-content">
          <Intro
            titleBGWhite={config.introTitleIndexBGWhite}
            titleBGBlue={config.introTitleIndexBGBlue}
            section="/#section-what"
          />
          <section className="no-padding-bottom"></section>
          <What />
          <section className="no-padding-bottom"></section>
          <Games />
          <section className="no-padding-bottom"></section>
          {/* <Price />
          <section className="no-padding-bottom"></section> */}
          <Directions />
          <section className="no-padding-bottom"></section>
          {/* <Faq /> */}
          <Partners />
          <section className="no-padding-bottom"></section>
          <Contact />
          <Footer />
        </div>
        <Scrolltotop />
      </Layout>
    )
  }
}

export default IndexPage
