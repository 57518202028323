import React, { Component } from "react"

import raiffeisen from "../assets/img/partners/raiffeisen.png"
import habegger from "../assets/img/partners/habegger.png"
import gastro from "../assets/img/partners/hallenstadion-gastro.png"
import ticketcorner from "../assets/img/partners/ticketcorner.jpg"
import zwanzigMin from "../assets/img/partners/20min.png"
import zuerisee from "../assets/img/partners/radio-zuerisee.png"
import rivella from "../assets/img/partners/rivellalogo.png"

export default class Partners extends Component {
  render() {
    return (
      <section id="section-partners" className="partner no-padding-bottom">
        <div className="container">
          <div className="row">
            <div className="col col-lg-12">
              <div className="col-inner">
                {/* Begin Heading */}
                <div className="heading heading-xlg heading-center">
                  <h1>
                    <span className="bg-white text-dark">Our</span>
                    <span className="bg-main text-white">Partners</span>
                  </h1>
                </div>
                {/* End Heading */}
                <br />
                <br />
                <div className="text-center max-width-900 margin-auto">
                  <ul>
                    <li>
                      <a
                        href="https://memberplus.raiffeisen.ch/de/konzerte-events/kartrace-on-ice"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="sponsor-image"
                          title="raiffeisen"
                          alt="raiffeisen"
                          src={raiffeisen}
                        />
                      </a>
                      <a
                        href="https://www.habegger.ch/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="sponsor-image"
                          title="habegger"
                          alt="habegger"
                          src={habegger}
                        />
                      </a>
                      <a
                        href="https://hallenstadion.ch/das-hallenstadion/team/gastronomie"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="sponsor-image"
                          title="hallenstadion gastro"
                          alt="hallenstadion gastro"
                          src={gastro}
                        />
                      </a>
                      <a
                        href="https://www.ticketcorner.ch/artist/kartrace/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="sponsor-image"
                          title="ticketcorner"
                          alt="ticketcorner"
                          src={ticketcorner}
                        />
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <img
                        className="sponsor-image"
                        title="20 min"
                        alt="20 min"
                        src={zwanzigMin}
                      />
                      <img
                        className="sponsor-image"
                        title="radio zürisee"
                        alt="radio zürisee"
                        src={zuerisee}
                      />
                      <img
                        className="sponsor-image"
                        title="rivella"
                        alt="rivella"
                        src={rivella}
                      />
                    </li>
                  </ul>
                </div>
              </div>{" "}
              {/* /.col-inner */}
            </div>{" "}
            {/* /.col */}
          </div>{" "}
          {/* /.row */}
        </div>{" "}
        {/* /.container */}
      </section>
    )
  }
}
