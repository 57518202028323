import React from "react"
import workbg from "../assets/img/kart-bg.jpg"
import { OutboundLink } from "gatsby-plugin-gtag"

import intesso from "../assets/img/intesso.png"
import hasta from "../assets/img/hallenstadion.png"
import kartschuer from "../assets/img/kartschuur.png"
// import situationsplan from "../assets/img/eingang.jpg"

export default function Directions() {
  return (
    <section
      id="section-direction"
      className="work split-box bg-image padding-bottom"
      style={{ backgroundImage: `url(${workbg})` }}
    >
      <div className="cover" />
      <div className="container-fluid text-white">
        <div className="row">

          {/* <div className="col-left col-xl-6 no-padding">
            <div className="col-inner">
              <div className="heading heading-xlg">
                <h1>
                  <span className="bg-main text-white">Where</span>
                </h1>
              </div>
              <p>Kartrace on Ice findet im Hallenstadion statt.</p>
              <p>Der Eingang ist auf der Seite der Messe Zürich.</p>

              <div>
                <a
                  href={situationsplan}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-danger btn-rounded btn-lg mr-3 mb-3"
                >
                  <i className="fas fa-map" /> Eingang
                </a>
                <OutboundLink
                  href="https://hallenstadion.ch/events-tickets/anfahrt-parking"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-danger btn-rounded btn-lg mr-3 mb-3"
                >
                  <i className="fas fa-map" /> Anfahrt
                </OutboundLink>
              </div>

              <div>
                <iframe
                  title="anfahrt"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d675.0104505443846!2d8.551680029281082!3d47.4111257986983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDfCsDI0JzQwLjEiTiA4wrAzMycwOC4wIkU!5e0!3m2!1sen!2sch!4v1606317282935!5m2!1sen!2sch"
                  width="100%"
                  height="380"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                ></iframe>
              </div>
            </div>
          </div> */}

          {/* /.col */}
          <div className="col-right col-xl-6 no-padding">
            <div className="col-inner">
              {/* Begin Heading */}
              <div className="heading heading-xlg">
                <h1>
                  <span className="bg-main text-white">Who</span>
                </h1>
              </div>
              {/* End Heading */}
              <p>Ein Gemeinschaftsprojekt von:</p>
              <OutboundLink target="_blank" href="https://hallenstadion.ch/">
                <img src={hasta} className="logo" alt="AG Hallenstadion" />
              </OutboundLink>
              <OutboundLink
                target="_blank"
                rel="noreferrer"
                href="http://intesso.com"
              >
                <img
                  src={intesso}
                  className="logo"
                  alt="intesso engineering gmbh"
                />
              </OutboundLink>
              <p className="mt-3">In Zusammenarbeit mit:</p>
              <OutboundLink
                target="_blank"
                rel="noreferrer"
                href="http://xn--kartschr-d6aa.ch/"
              >
                <img src={kartschuer} className="logo" alt="AG Hallenstadion" />
              </OutboundLink>
            </div>
            {/* /.col-inner */}
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  )
}
