import React from "react"
import gamesbg from "../assets/img/track.jpg"
import "react-multi-carousel/lib/styles.css"
import snake from "../assets/documents/Snake.pdf"
import race1 from "../assets/documents/Race1.pdf"
import race2 from "../assets/documents/Race2.pdf"
import spielbeschreibung from "../assets/documents/spielbeschreibung.pdf"
import spielregeln from "../assets/documents/spielregeln.pdf"

export default function Games() {
  return (
    <section
      id="section-games"
      className="work split-box bg-image"
      style={{ backgroundImage: `url(${gamesbg})` }}
    >
      {/* Element cover */}
      <div className="cover" />
      <div className="container-fluid text-white">
        <div className="row">
          <div className="col-left col-xl-6 no-padding">
            <div className="col-inner">
              {/* Begin Heading */}
              <div className="heading heading-xlg">
                <h1>
                  <span className="bg-white text-dark">The</span>
                  <span className="bg-main text-white">Games</span>
                </h1>
              </div>
              {/* End Heading */}
              <p>
                Du buchst einen Spielblock à 15 Minuten. Ein Spielblock besteht
                aus drei Spielen zu je fünf Minuten, die du am Stück spielst.
                Das erste Spiel ist ein “Snake”, das von zwei unterschiedlichen
                Rennen gefolgt wird.
              </p>
              <p>
                Die Spiele sind so abgestimmt, dass nach und nach eine
                Steigerung erlebbar ist. Je besser du den Kart kennen lernst,
                desto unbeschwerter lässt es sich in unsere real erlebbare
                Virtualität eintauchen. Ein unvergessliches Erlebnis ist dir
                garantiert.
              </p>
              <p>Achtung: Hier herrscht Suchtgefahr!</p>
              <a
                href={spielbeschreibung}
                target="_blank"
                rel="noreferrer"
                className="btn btn-danger btn-rounded btn-lg mr-3 mb-3"
              >
                Spielbeschreibung &nbsp;&nbsp;
                <i className="fas fa-clipboard-list" />
              </a>{" "}
              <a
                href={spielregeln}
                target="_blank"
                rel="noreferrer"
                className="btn btn-danger btn-rounded btn-lg mr-3 mb-3"
              >
                Spielregeln &nbsp;&nbsp;
                <i className="fas fa-exclamation-triangle" />
              </a>
              <br />
              {/* <a href="#" className="btn btn-white btn-rounded btn-lg">Read More</a> */}
            </div>{" "}
            {/* /.col-inner */}
          </div>{" "}
          {/* /.col */}
          <div className="col-right col-xl-6">
            <div className="col-inner">
              {/* Begin info box wrap */}
              <div className="info-box-wrapper">
                <div className="row">
                  <div className="col col-12">
                    {/* Begin info box */}
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-gamepad" />
                      </span>
                      <div className="info-box-heading">
                        <h3>
                          <a target="_blank" rel="noreferrer" href={snake}>
                            Snake
                          </a>
                        </h3>
                        <div className="divider" />
                      </div>
                      <div className="info-box-info">
                        <p>
                          Nach der Registration und der Einführung kannst du
                          gleich selber die Funktionsweise des Karts
                          ausprobieren. Je mehr interaktive Elemente du
                          aufsammelst, desto länger wird deine Schlange. Dabei
                          darfst du nicht in eine gegnerische Schlange
                          hineinfahren. Mit unterschiedlichen Power-Ups kannst
                          du dir selber Vorteile, deinen Gegnern aber auch
                          Nachteile verschaffen.
                        </p>
                      </div>
                    </div>
                    {/* End info box */}
                  </div>{" "}
                  {/* /.col */}
                  <div className="col col-12">
                    {/* Begin info box */}
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-gamepad" />
                      </span>
                      <div className="info-box-heading">
                        <h3>
                          <a target="_blank" rel="noreferrer" href={race1}>
                            Race #1
                          </a>
                        </h3>
                        <div className="divider" />
                      </div>
                      <div className="info-box-info">
                        <p>
                          Beim ersten Rennen kannst du zeigen, dass du das
                          Fahren mit dem Elektro-Driftkart im Griff hast, und
                          die Power-Ups gezielt einsetzen kannst. Der Kurs
                          beinhaltet schöne, langgezogene Kurven und ein paar
                          schnelle Wechsel. Aber schnell Fahren ist nicht alles!
                          Genaues und cleveres Fahren zahlt sich aus. Touchierst
                          du nämlich die “Abschrankung” verlangsamt sich dein
                          Kart beträchtlich.
                        </p>
                      </div>
                    </div>
                    {/* End info box */}
                  </div>{" "}
                  {/* /.col */}
                  <div className="col col-12">
                    {/* Begin info box */}
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-gamepad" />
                      </span>
                      <div className="info-box-heading">
                        <h3>
                          <a target="_blank" rel="noreferrer" href={race2}>
                            Race #2
                          </a>
                        </h3>
                        <div className="divider" />
                      </div>
                      <div className="info-box-info">
                        <p>
                          Nimm deine Erfahrungen aus dem ersten Rennen mit und
                          zeige auf dem anspruchsvolleren Kurs dein Können. Du
                          kannst verschiedene Power-Ups einsammeln. Leg zum
                          Beispiel deinen Gegner für ein Überholmanöver
                          kurzfristig lahm oder schalte den Turbo-Boost. Im
                          richtigen Moment eingesetzt, erhöhen sie deine
                          Gewinnchancen.
                        </p>
                      </div>
                    </div>
                    {/* End info box */}
                  </div>{" "}
                  {/* /.col */}
                </div>{" "}
                {/* /.row */}
              </div>
              {/* End info box wrap */}
            </div>{" "}
            {/* /.col-inner */}
          </div>{" "}
          {/* /.col */}
        </div>{" "}
        {/* /.row */}
      </div>{" "}
      {/* /.container */}
    </section>
  )
}
