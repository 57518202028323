import React from "react"
import config from "../../config"

export default function Nav(props) {
  return (
    <nav
      id="cd-lateral-nav"
      className={
        props.isMenuOpen ? "lateral-menu-is-open" : "lateral-menu-is-closed"
      }
    >
      <div className="nav-inner">
        {/* menu header */}
        <div className="menu-header">Menu</div>
        {/* Begin single links wrapper */}
        <ul className="cd-navigation cd-single-item-wrapper">
          <li>
            <a onClick={props.hamburgerClicked} href="#section-intro">
              Home
            </a>
          </li>
          <li>
            <a onClick={props.hamburgerClicked} href="#section-what">
              What You Can Do
            </a>
          </li>
          <li>
            <a onClick={props.hamburgerClicked} href="#section-games">
              Games
            </a>
          </li>
          {/* <li>
            <a onClick={props.hamburgerClicked} href="#section-price">
              Price / Hours
            </a>
          </li> */}
          <li>
            <a onClick={props.hamburgerClicked} href="#section-direction">
              {/* Where /  */}
              Who
            </a>
          </li>
          <li>
            <a onClick={props.hamburgerClicked} href="#section-partners">
              Partners
            </a>
          </li>
          {/* <li>
            <a onClick={props.hamburgerClicked} href="#section-faq">
              FAQ
            </a>
          </li> */}
          <li>
            <a onClick={props.hamburgerClicked} href="#section-contact">
              Contact Us
            </a>
          </li>
        </ul>
        {/* End single links wrapper */}
        {/* Begin social icons */}
        <div className="social-icons">
          {config.socialLinks.map(social => {
            const { icon, url } = social
            return (
              <a
                key={url}
                href={url}
                target="_blank"
                rel="noreferrer"
                className={`mx-2`}
              >
                <i className={`fab ${icon}`}></i>
              </a>
            )
          })}
        </div>
        {/* End social icons */}
      </div>
      {/* /.nav-inner */}
    </nav>
  )
}
