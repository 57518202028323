import React from "react"
import config from "../../config"
import workbg from "../assets/img/kart-bg.jpg"
import schutzmassnahmen from "../assets/documents/Schutzkonzept.pdf"

export default function What() {
  return (
    <section
      id="section-what"
      className="work split-box bg-image"
      style={{ backgroundImage: `url(${workbg})` }}
    >
      {/* Element cover */}
      <div className="cover" />
      <div className="container-fluid text-white">
        <div className="row">
          <div className="col-left col-xl-6 no-padding">
            <div className="col-inner">
              {/* Begin Heading */}
              <div className="heading heading-xlg">
                <h2>
                  Der Betrieb ist eingestellt.
                </h2>
                <h2>
                  Es hat uns gefreut, dass du da warst.
                </h2>
                <h1>
                  <span className="bg-white text-dark">If not, here's</span>
                  <span className="bg-main text-white">what you missed</span>
                </h1>
              </div>
              {/* End Heading */}
              <p>
                Fahre unter dem Motto «Where game meets reality» durch das
                Hallenstadion Zürich mit technisch umgebauten Elektro-Karts.
              </p>
              <p>
                Schnapp dir einen der Elektrokarts und erlebe Kartfahren in
                neuen Dimensionen.
              </p>
              <p>
                Drifte um die Ecken, hole dir Power-Ups, lege deine
                Herausforderer lahm und ziehe mit einem Lächeln vorbei.
              </p>

              {/* <h2 className="pb-5">
                <a
                  href={config.ticketing}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-danger btn-rounded btn-lg mr-3 mb-3"
                >
                  Ticket kaufen &nbsp;&nbsp;
                  <i className="fas fa-ticket-alt" />
                </a>
              </h2> */}

              {/* <div className="heading heading-xlg">
                <h1>
                  <span className="bg-white text-dark">plan</span>
                  <span className="bg-main text-white">Your Visit</span>
                </h1>
              </div>

              <p className="p-3 bg-white text-main">
                Bitte komme mindestens 30 Minuten vor deinem gebuchten Slot um
                allfällige Wartezeiten zu kompensieren. Bei Verspätung kann die
                Slotzeit nicht garantiert werden.
              </p>

              <p>
                Warme Kleidung und Handschuhe sind empfehlenswert. Wir fahren
                auf Eis! Für Kinder empfehlen wird das Tragen eines Velo- oder
                Skihelms.
              </p>

              <p>
                Kartrace on Ice wird Corona-konform durchgeführt. Bitte beachte
                unsere Schutzmassnahmen. «Ab sofort gilt für alle Besucher ab 16
                Jahren im Hallenstadion 2G und eine Maskenpflicht (2G
                Covid-Zertifikat mit QR-Code). Dies bedeutet dass der Einlass ab
                16 Jahren nur für geimpfte und genesene Besucher möglich ist.
                Auf der Seite des BAG erfahren Sie, wie Sie zu Ihrem Zertifikat
                kommen. Zusätzlich gilt für alle Besucher ab 12 Jahren eine
                Maskenpflicht und für die Konsumation von Essen + Getränken gibt
                es eine Sitzpflicht.»
              </p>

              <h2>
                <a
                  href={schutzmassnahmen}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-danger btn-rounded btn-lg mr-3 mb-3"
                >
                  Schutzmassnahmen&nbsp;&nbsp;
                  <i className="fas fa-user-shield"></i>
                </a>
              </h2> */}

              {/* <a href="#" className="btn btn-white btn-rounded btn-lg">Read More</a> */}
            </div>{" "}
            {/* /.col-inner */}
          </div>{" "}
          {/* /.col */}
          <div className="col-right col-xl-6">
            <div className="col-inner">
              {/* Begin info box wrap */}
              <div className="info-box-wrapper">
                <div className="row">
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      src="https://www.youtube.com/embed/PielMaaCVKI"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      width={1280}
                      height={720}
                      frameBorder={0}
                      title="Kartrace on Ice - Where Game meets Reality"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      src="https://www.youtube.com/embed/R3swxTHQTNQ"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      width={1280}
                      height={720}
                      frameBorder={0}
                      title="Kartrace on Ice - interactive Games - Where Game meets Reality"
                    />
                  </div>
                </div>
                <div className="row" hidden>
                  <div className="col col-12 col-md-6">
                    {/* Begin info box */}
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-tachometer-alt" />
                      </span>
                      <div className="info-box-heading">
                        <h3>Action</h3>
                        <div className="divider" />
                      </div>
                      <div className="info-box-info">
                        <p>Strategisches Fahren oder lieber rasante Aktion?</p>
                      </div>
                    </div>
                    {/* End info box */}
                  </div>{" "}
                  {/* /.col */}
                  <div className="col col-12 col-md-6">
                    {/* Begin info box */}
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-directions" />
                      </span>
                      <div className="info-box-heading">
                        <h3>Drifting</h3>
                        <div className="divider" />
                      </div>
                      <div className="info-box-info">
                        <p>Gemächlich geradeaus oder waghalsig driften?</p>
                      </div>
                    </div>
                    {/* End info box */}
                  </div>{" "}
                  {/* /.col */}
                  <div className="col col-12 col-md-6">
                    {/* Begin info box */}
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-rocket" />
                      </span>
                      <div className="info-box-heading">
                        <h3>Boost</h3>
                        <div className="divider" />
                      </div>
                      <div className="info-box-info">
                        <p>Mit oder ohne Turbo-Boost?</p>
                      </div>
                    </div>
                    {/* End info box */}
                  </div>{" "}
                  {/* /.col */}
                  <div className="col col-12 col-md-6">
                    {/* Begin info box */}
                    <div className="info-box">
                      <span className="info-box-icon">
                        <i className="fas fa-users" />
                      </span>
                      <div className="info-box-heading">
                        <h3>Together</h3>
                        <div className="divider" />
                      </div>
                      <div className="info-box-info">
                        <p>Gegeneinander oder miteinander antreten?</p>
                      </div>
                    </div>
                    {/* End info box */}
                  </div>{" "}
                  {/* /.col */}
                </div>{" "}
                {/* /.row */}
              </div>
              {/* End info box wrap */}
            </div>{" "}
            {/* /.col-inner */}
          </div>{" "}
          {/* /.col */}
        </div>{" "}
        {/* /.row */}
      </div>{" "}
      {/* /.container */}
    </section>
  )
}
