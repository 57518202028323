import React from "react"
import { useEffect } from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export default function Scrolltotop() {
  useEffect(() => {
    function handleScroll(e) {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      const scrolltotop = document.querySelector(".scrolltotop")
      if (scrollTop > document.getElementById("section-intro").clientHeight) {
        scrolltotop.style.visibility = "visible"
      } else {
        scrolltotop.style.visibility = "hidden"
      }
    }
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <AnchorLink className="scrolltotop hide-from-xs" to="/#section-intro">
      <i className="fas fa-arrow-up"></i>
    </AnchorLink>
  )
}
