import React from "react"
import config from "../../config"
import map from "../assets/img/hallenstadion-bg.jpg"

export default function Contact() {
  return (
    <section
      id="section-contact"
      className="page contact-section bg-dark text-white bg-image"
      style={{ backgroundImage: `url(${map})` }}
    >
      {/* Element cover */}
      <div className="cover" />
      <div className="container">
        <div className="row">
          <div className="col-left col-lg-6" style={{ paddingRight: 0 }}>
            <div className="col-inner">
              {/* <div className="contact-info">
                <p>
                  <a
                    href="https://goo.gl/maps/gvaZXPpab2DKZ2b4A"
                    target="_blank"
                    rel="noreferrer"
                  >
                    HALLENSTADION
                    <br />
                    Wallisellenstrasse 45
                    <br />
                    8050 Zürich
                  </a>
                </p>
                <p>
                  <i className="fas fa-phone" />
                  {" " + config.phone}
                </p>
                <p>
                  <i className="fas fa-envelope" />
                  {" " + config.email}
                </p>
              </div> */}
            </div>{" "}
            {/* /.col-inner */}
          </div>{" "}
          {/* /.col */}
          <div className="col-right col-lg-6">
            <div className="col-inner">
              {/* Begin social icons */}
              <div className="social-icons">
                {config.socialLinks.map(social => {
                  const { icon, url } = social
                  return (
                    <a
                      key={url}
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      className={`mx-2`}
                    >
                      <i className={`fab ${icon}`}></i>
                    </a>
                  )
                })}
              </div>
              {/* End social icons */}
              <a
                href={`${config.request}`}
                target="_blank"
                rel="noreferrer"
                className="btn btn-danger btn-rounded btn-lg"
              >
                Das Angebot{"  "}
                <i className="fas fa-paper-plane" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
