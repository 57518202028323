import React from "react"
import { Link } from "gatsby"

// FIXME add kartrace-on-ice-logo.jpg
// import logo from "../assets/img/kartrace-logo.jpg"

function Header(props) {
  return (
    <header id="header">
      <Link id="cd-logo" to="/">
        <span className="bg-main text-white custom-logo">
          KART<span className="notbold">RACE</span> ON ICE
        </span>
        {/* FIXME add kartrace-on-ice-logo.jpg  */}
        {/* <img src={logo} alt="Kartrace on Ice" /> */}
      </Link>
      <button
        id="cd-menu-trigger"
        className={props.isMenuOpen ? "is-clicked" : "is-not-clicked"}
        onClick={props.hamburgerClicked}
      >
        <span className="cd-menu-icon"></span>
      </button>
    </header>
  )
}

export default Header
