import React from "react"
import introImg from "../assets/img/intro/kart-game-race-stadion.jpg"
import introvideoMp4 from "../assets/img/intro/kartgames.mp4"
import introvideoWebm from "../assets/img/intro/kartgames.webm"
// import gruppenPackages from "../assets/documents/Gruppen-Packages.pdf"

import News from "./News"
// import config from "../../config"
import { OutboundLink } from "gatsby-plugin-gtag"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export default function Intro(props) {
  return (
    <section id="section-intro" className="intro-parallax full-height">
      {/* fade over gray */}
      <div
        className="full-cover bg-image"
        style={{
          backgroundColor: "gray",
          width: "100%",
          height: "100%",
          zIndex: 1,
          opacity: 0.1,
        }}
      />

      <div className="full-cover">
        <video
          playsInline
          autoPlay
          muted
          loop
          id="introvideo"
          poster={introImg}
        >
          <source src={introvideoWebm} type="video/webm"></source>
          <source src={introvideoMp4} type="video/mp4"></source>
        </video>
      </div>

      {/* Begin intro caption */}

      <div className="intro-caption intro-caption-video text-white">
        <h1>
          <div>
            <span className="bg-white text-dark">{props.titleBGWhite}</span>
            <span className="bg-main text-white">{props.titleBGBlue}</span>
          </div>
          <News />
        </h1>

        {/* <h2>
          <a
            href={config.ticketing}
            target="_blank"
            rel="noreferrer"
            className="btn btn-danger btn-rounded btn-lg mr-3 mb-3"
          >
            Ticket kaufen &nbsp;&nbsp;
            <i className="fas fa-ticket-alt" />
          </a>
          <a
            href={gruppenPackages}
            target="_blank"
            rel="noreferrer"
            className="btn btn-danger btn-rounded btn-lg mr-3 mb-3"
          >
            Gruppen Packages&nbsp;&nbsp;
            <i className="fas fa-glass-cheers"></i>
          </a>
        </h2> */}
      </div>
      {/* End intro caption */}
      {/* Begin made with love :) */}
      <div className="made-with-love hide-from-xs">
        <OutboundLink
          href="http://intesso.com"
          target="_blank"
          rel="noreferrer"
        >
          <p className="text-dark">
            made with{" "}
            <span className="text-purple">
              <i className="far fa-heart" />
            </span>
          </p>
        </OutboundLink>
      </div>
      {/* End made with love */}
      {/* Scroll down arrow */}
      <AnchorLink
        className="scroll-down-arrow page-scroll text-center"
        to="/#section-what"
      >
        <span>
          <i className="fas fa-arrow-down" />
        </span>
      </AnchorLink>
    </section>
  )
}
