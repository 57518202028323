import React from "react"
import config from "../../config"

export default function News() {
  const showNews = config.news1 || config.news2
  return (
    showNews && (
      <span id={config.newsState} className="news bg-white text-dark">
        {config.news1} <br />
        {config.news2}
      </span>
    )
  )
}
